<template>
  <div>

    <!-- Değişken Katsayılı Birim Hesaplaması -->
    <div class="flex justify-between gap-5 mb-2.5" v-if="isProductVariableCoefficientUnitCalculationIsActive || isProductVariableCoefficientUnitSumIsActive">
      <div v-if="isProductVariableCoefficientUnitCalculationIsActive">
        <SectionProductVariableCoefficientUnitCalculation
            size="default"
            :price="selectedVariant?.primaryStockData?.wholesalePrice"
            :currency="selectedVariant?.primaryStockData.currency"
            :maxQtyForProduct="maxQtyForProduct(product)"
            :unitName="selectedVariant?.unitName"
            v-model:qty="cartAttributes.qty"
            v-model:width="cartAttributes.width"
            v-model:totalQty="cartQuantity"
            v-if="parseInt(selectedVariant?.qtySumForAllWarehouse) !== 0"
        />
      </div>

      <!-- Değişken Katsayılı Birim Hesap Toplamı -->
      <div v-if="isProductVariableCoefficientUnitSumIsActive">
        <SectionProductVariableCoefficientUnitSum
            :price="selectedVariant?.primaryStockData?.wholesalePrice"
            :currency="selectedVariant?.primaryStockData.currency"
            :totalQty="cartQuantity"
            :unitName="selectedVariant?.unitName"
            v-if="parseInt(selectedVariant?.qtySumForAllWarehouse) !== 0"
        />
      </div>
    </div>

    <!-- KG/Gram Seçimi -->
    <div class="mb-5" v-if="quantity !== 0 && selectedVariant?.unitType === 2">
      <RadioGroup v-model="cartQuantity">
        <RadioGroupLabel class="sr-only">{{ $t('listing.weight-selection') }}</RadioGroupLabel>
        <div class="flex flex-col sm:flex-row gap-2.5 max-w-lg">
          <RadioGroupOption as="template" v-for="item in weights" :key="item.weight" :value="item.weight" v-slot="{ active, checked }">
            <div :class="[active ? 'border-primary' : 'border-gray-300', 'relative cursor-pointer rounded-lg border bg-white px-5 py-2.5 shadow-sm focus:outline-none flex justify-between flex-1']">
              <div class="flex items-center">
                <div class="flex flex-col">
                  <RadioGroupLabel as="div" class="font-medium text-lg text-gray-900">{{ item.weight }}/gr</RadioGroupLabel>
                  <RadioGroupDescription as="div" class="flex text-sm text-right space-x-2">
                    <div :class="[getDiscountedPrice(item.price) ? 'text-xs opacity-40 line-through' : '', 'whitespace-nowrap']">{{ item.price }} TL</div>
                    <div class="whitespace-nowrap" v-if="getDiscountedPrice(item.price)">{{ getDiscountedPrice(item.price) }} TL</div>
                  </RadioGroupDescription>
                </div>
              </div>

              <span :class="[active ? 'border-2' : 'border-2', checked ? 'border-secondary' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
    </div>

    <div class="flex md:flex-row gap-2.5">
      <div class="bg-gray-100 p-3 font-medium text-base rounded-md flex items-center w-full" v-if="isShowcaseMode">
        <InformationCircleIcon class="h-4 w-4 mr-2.5"/>
        {{ showcaseDescription }}
      </div>

      <template v-else>

        <div class="mb-2" v-if="quantity !== 0 && selectedVariant?.unitType !== 2 && !isProductVariableCoefficientUnitCalculationIsActive">
          <FormInputCounter v-model="cartQuantity" :min="1" :max="maxQtyForProduct(product)" :step="quantityStep"/>
        </div>

        <!-- :class="{'md:ml-2' : quantity !== 0 && selectedVariant?.unitType !== 2}" -->
        <div class="w-full mb-2 hidden sm:block">
          <div v-if="quantity <= 0" class="btn-sold">
            <StockOutText />
          </div>
          <FormButton
              v-if="quantity > 0"
              color="primary"
              type="button"
              class="uppercase w-full"
              :loading="addCartLoading"
              @click="addToBasket(selectedVariant.variantId, cartQuantity, selectedVariant?.primaryStockData.rawCurrency, product.selectedVariant?.primaryStockData.warehouseId, null, true, false, finalPrice, null, cartAttributes)"
          >
            <ShoppingBagIcon class="h-4 w-4 mr-2"/>
            {{ $t('app.add-to-cart') }}
          </FormButton>
        </div>
        <!-- Mobile buttons -->
        <div class="w-full sm:hidden fixed sm:top-auto sm:right-auto bottom-0 left-0 sm:col-span-1 z-50">
          <div class="py-2.5 px-4 bg-white/30 backdrop-blur-md border border-gray-200 flex justify-between">
            <div
                v-if="quantity <= 0"
                class="btn-sold"
            >
              <StockOutText />
            </div>
            <FormButton
                v-if="quantity > 0"
                color="primary"
                type="button"
                class="uppercase w-full"
                :loading="addCartLoading"
                @click="addToBasket(selectedVariant.variantId, cartQuantity, selectedVariant?.primaryStockData.rawCurrency, product.selectedVariant?.primaryStockData.warehouseId, null, true, false, finalPrice)"
            >
              <ShoppingBagIcon class="h-4 w-4 mr-2"/>
              {{ $t('app.add-to-cart') }}
            </FormButton>
          </div>
        </div>
      </template>

      <div v-if="status === 'authenticated'">
        <FormButton
            color="gray"
            type="button"
            @click="addWishlist(selectedVariant)"
            :aria-label="$t('listing.add-to-wishlist')"
        >
          <HeartFilledIcon v-if="selectedVariant?.isFavorited" class="text-red w-5 h-5"/>
          <HeartIcon v-if="!selectedVariant?.isFavorited" class="text-primary w-5 h-5"/>
        </FormButton>
      </div>
      <div v-if="status === 'unauthenticated'">
        <FormButton
            color="gray"
            type="button"
            @click="authModalIsVisible = true"
            :aria-label="$t('listing.add-to-wishlist')"
        >
          <HeartIcon class="text-primary w-5 h-5"/>
        </FormButton>
      </div>
    </div>
    <AppWidgetBuyViaWhatsApp/>
  </div>

</template>

<script setup>
import {HeartIcon, ShoppingBagIcon, InformationCircleIcon} from "@heroicons/vue/24/outline";
import {HeartIcon as HeartFilledIcon} from "@heroicons/vue/24/solid";
import StockOutText from "~/components/StockOutText.vue";
import collect from "collect.js";
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

const storeConfig = useStoreConfig()
const { isProductVariableCoefficientUnitCalculationIsActive, isProductVariableCoefficientUnitSumIsActive } = storeToRefs(storeConfig)

const {
  product,
  selectedVariant,
  cartAttributes,
  cartQuantity,
  isShowcaseMode,
  showcaseDescription,
  weights,
  finalPrice
} = await useProduct()

const {
  addCartLoading,
  addToBasket
} = await useBasket()

const {
  addWishlist
} = useWishlist()

const {maxQtyForProduct} = useListing()

const {status} = await useAuth();
const {isWholesaleUser, authModalIsVisible} = await useCustomer();

const quantity = computed(() => {
  return parseInt(selectedVariant.value?.primaryStockData?.quantity)
})

const quantityStep = computed(() => {
  return selectedVariant.value?.primaryStockData?.quantityStep
})

const discountConfig = computed(() => selectedVariant.value?.primaryStockData?.discount?.config)
const getDiscountedPrice = (price) => {
  if (discountConfig.value?.type === 'percent') {
    return price - ((price / 100) * discountConfig.value?.value);
  } else if (discountConfig.value?.type === 'value') {
    return price - discountConfig.value?.value;
  } else {
    return null
  }
}

watch(cartQuantity, (newQuantity) => {
  if (selectedVariant.value?.unitType === 2 && weights.value?.length > 0) {
    const newPrice = collect(weights.value).where('weight', newQuantity).pluck('price').first();
    const discountPrice = getDiscountedPrice(newPrice);
    const primaryStockData = selectedVariant.value.primaryStockData

    if (typeof newPrice !== 'undefined') {
      primaryStockData.price = newPrice
    }

    if (typeof discountPrice !== 'undefined') {
      primaryStockData.discountPrice = discountPrice
    }

    selectedVariant.value = {
      ...selectedVariant.value,
      primaryStockData : primaryStockData
    }
  }
})

</script>

<style scoped>

</style>